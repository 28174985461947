import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Jobs = () => {
	return (
		<section className="section-padding">
			<div className="">
				<div className="container_section">
					<ul className="list_jobs">
						<li className="job_item first">
							<a className="job_link" href="/sargaco">
								<StaticImage
									className="image_job"
									src="../images/IMG_01466.jpg"
								/>
								<h2 className="title_job">sargaço</h2>
							</a>
						</li>
						<li className="job_item">
							<a className="job_link" href="/mulher-pandemica">
								<StaticImage
									className="image_job"
									src="../images/Mulher1.jpg"
								/>
								<h2 className="title_job">mulher Pandêmica</h2>
							</a>
						</li>
						<li className="job_item">
							<a className="job_link" href="/mamilos">
								<StaticImage
									className="image_job img"
									src="../images/IMG_1729.JPG"
								/>
								<h2 className="title_job">mamilos</h2>
							</a>
						</li>
						<li className="job_item">
							<a className="job_link" href="/seiva">
								<StaticImage
									className="image_job img"
									src="../images/site.jpeg"
								/>
								<h2 className="title_job">seiva</h2>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Jobs;
