import React from 'react';
import yt from '../svg/black-and-white-youtube.svg';
import ig from '../svg/black-instagram.svg';

const SocialMedia = () => {
	return (
		<footer id="footer" className="footer" role="contentinfo">
			<div className="">
				<div className="container_socialmedia">
					<li className="social_link">
						<a
							target="_blank"
							href="https://www.instagram.com/pi_mire/"
							title="instagram"
							className="social_media">
							<p className="social_media_name">ig</p>
						</a>{' '}
					</li>
					{/*<li className="social_link">
						<a
							 target="_blank"
							href="https://www.instagram.com/pi_mire/"
							title="youtube"
							className="social_media">
							<p className="social_media_name" >yt</p>
						</a>
	</li>*/}
				</div>
			</div>
		</footer>
	);
};

export default SocialMedia;
