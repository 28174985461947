import React from 'react';
import { Link } from 'gatsby';

const Nav = () => {
	return (
		<nav className="main-nav">
			<Link to="/sobre" className="main-nav-item">
				sobre
			</Link>
			<Link to="/trabalhos" className="main-nav-item">
				trabalhos
			</Link>
			<Link to="/midia" className="main-nav-item">
				mídia
			</Link>
			<Link to="/contato" className="main-nav-item">
				contato
			</Link>
		</nav>
	);
};

export default Nav;
