import React from 'react';
import PropTypes from 'prop-types';
import SocialMedia from './SocialMedia';

const Footer = ({ title }) => {
	return (
		<footer id="footer" className="footer" role="contentinfo">
			<div className="">
				<div className="container_line">
					<span className="copyright">
						©{title} {new Date().getFullYear()}
					</span>
					<span className="line"></span>
					<SocialMedia />
				</div>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Footer;
